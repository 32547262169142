import * as React from 'react';

import styled from 'styled-components';
import { Collapse, Row, Col } from 'antd';

import { t } from '../../utils';
import Content from '../content';

const Panel = Collapse.Panel;
const Wrapper = styled.div`
	h2 {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 400;
	}
`;

interface Props {
	showtitle?: boolean;
}

interface State {

}

const customPanelStyle = {
	background  : 'white',
	borderRadius: 4,
	marginBottom: 24,
	border      : 0,
	overflow    : 'hidden',
};

export class Faq extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content>
				{this.props.showtitle != false && <h2>{t('faq.title')}</h2>}

				<Row gutter={32}>
					<Col lg={12} xs={24}>
						<Collapse bordered={false} style={{ background: 'transparent' }}>
							<Panel header={<b>{t('faq.lockdown_title')}</b>} key="1"
								 style={customPanelStyle}>
								<p>{t('faq.lockdown_desc')}</p>
							</Panel>
							<Panel header={<b>{t('faq.parental_title')}</b>} key="2"
								 style={customPanelStyle}>
								<p>{t('faq.parental_desc')}</p>
								<p dangerouslySetInnerHTML={{ __html: t('faq.parental_desc_2') }}/>
							</Panel>
							<Panel header={<b>{t('faq.max_configs_title')}</b>} key="3"
								 style={customPanelStyle}>
								<p>{t('faq.max_configs_desc')}</p>
							</Panel>
							<Panel header={<b>{t('faq.max_rules_title')}</b>} key="4"
								 style={customPanelStyle}>
								<p dangerouslySetInnerHTML={{ __html: t('faq.max_rules_desc') }}/>
							</Panel>
							<Panel header={<b>{t('faq.limitations_title')}</b>} key="5"
								 style={customPanelStyle}>
								<p dangerouslySetInnerHTML={{ __html: t('faq.limitations_desc') }}/>
							</Panel>
						</Collapse>
					</Col>

					<Col lg={12} xs={24}>
						<Collapse
							defaultActiveKey={['1']}
							bordered={false}
							style={{ background: 'transparent' }}
						>
							<Panel header={<b>{t('faq.trial_title')}</b>} key="11"
								 style={customPanelStyle}>
								<p dangerouslySetInnerHTML={{ __html: t('faq.trial_desc') }}/>
							</Panel>
							<Panel header={<b>{t('faq.ads_title')}</b>} key="12"
								 style={customPanelStyle}>
								<p>{t('faq.ads_desc')}</p>
							</Panel>
							<Panel header={<b>{t('faq.slow_title')}</b>} key="13"
								 style={customPanelStyle}>
								<p>{t('faq.slow_desc')}</p>
								<ol>
									<li>{t('faq.slow_desc_1')}</li>
									<li>{t('faq.slow_desc_2')}</li>
									<li>{t('faq.slow_desc_3')}</li>
								</ol>
							</Panel>
							<Panel header={<b>{t('faq.unblock_title')}</b>} key="14"
								 style={customPanelStyle}>
								<p>{t('faq.unblock_desc')}</p>
							</Panel>
						</Collapse>
					</Col>
				</Row>

			</Content>
		</Wrapper>;
	}
}

export default Faq;
